<template>
  <div class="questionbg">
		<div style="height: 118px;"></div>
		<div class="que-centent">
			<div style="line-height:100px;font-size: 18px;font-weight: bold;">请选择体质辨识的问卷</div>
			<div :class=" type=='1' ? 'que-btn current': 'que-btn' " @click="type='1'">九种体质辨识</div>
			<div :class=" type=='2' ? 'que-btn current': 'que-btn' " @click="type='2'">九种体质辨识（精简版）</div>
		</div>
		<div style="height:120px;text-align: center;padding: 0 30px;">
			<div>
				<p style="font-size: 12px;color: #757575;line-height: 18px;height: 28px;"><i class="icon-gantanhao-gth" style="vertical-align: middle;"></i>提交问卷后，可在采集设备上直接导入问诊结</p>
				<p>
					<zk-btn @click="gotoRoute" :disable=" type ? false : true " >确 认</zk-btn>
				</p>
			</div>
		</div>
  </div> 
</template>

<script>

export default {
	name: "questionbrief",
	data() {    
		return {			
			type:""      
		}
	},
   mounted(){
      
   },
   methods: {
		gotoRoute(){			
			this.$router.push({
				name:'questiondetail',
				params:{
					code:this.type,
				},
			})
		}
   },
};
</script>
<style lang="less" scoped="scoped">
	.questionbg{
		background:url("/images/bg_wenjuan1.png");background-size: 100% 100%;position:fixed;inset:0;display: flex;flex-direction: column;
	}
	.que-centent{
		text-align: center;padding:0 40px; flex: 1;
		.que-btn{
			border-radius:8px;background: #fff;line-height: 80px;margin-bottom: 30px;text-align: center;border: solid 1px #EBEBEB;color: #282828;font-size: 16px;
		}
		.que-btn.current{
			border: solid 1px #1195FE;color: #2D6FDF;
		}
	}
	.enter-btn{border-radius: 50px;line-height: 46px;margin:10px 30px;background: #bbb;color: #555;}
	.enter-btn.current{background: #2D6FDF;color: #fff;}
	
</style>